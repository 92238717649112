import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { NavController } from '@ionic/angular';
import { IPageBase, PersonalAddressService } from '@wlp/ui-bs-signup';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-personal-adress',
  templateUrl: './personal-address.page.html',
  styleUrls: ['./personal-address.page.scss'],
})
export class PersonalAddressPage implements IPageBase {
  formPersonalAddress: FormGroup;
  formErrorMessages: any;
  listUf: Array<any>;
  brand: string;
  navigationBackground: string;
  private zipCode: string
  constructor(
    private personalAddressService: PersonalAddressService,
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private storage: Storage
  ) {
    this.formPersonalAddress = this.personalAddressService.getFormPersonalAddress();
    this.formErrorMessages = this.personalAddressService.getFormErrorMessage();
    this.listUf = this.personalAddressService.getUf();
    this.personalAddressService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }

  async ionViewWillEnter(){
    this.zipCode = sessionStorage.getItem('zipCodeValue')
    this.formPersonalAddress.controls['postalCode'].setValue(this.zipCode);
    if(this.zipCode){
      this.searchPostalCodeAuto(this.zipCode)
    }
  }

  async continue() {

    let typeFlow
    typeFlow = sessionStorage.getItem('typeFlow')

    if(typeFlow === 'LEGAL_PERSON'){
      this.personalAddressService.sendPersonalAddress().subscribe(
        (res) => this.navCtrl.navigateRoot(['partners']),
        (err) => this.personalAddressService.getErrorPersonalAddress(err)
      );
    } else {
      this.personalAddressService.sendPersonalAddress().subscribe(
        (res) => this.navCtrl.navigateRoot('create-password'),
        (err) => this.personalAddressService.getErrorPersonalAddress(err)
      );
    }
  }

  searchPostalCode(data: any) {
    this.personalAddressService.searchPostaCode(data.target.value);
  }

  searchPostalCodeAuto(data: any) {
    this.personalAddressService.searchPostaCode(data);
  }

  goBack() {
    this.navCtrl.navigateForward('ocr-scanner');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  public getImageTag(tag: string) {
    let item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }
}
