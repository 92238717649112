import { ModalBilletReviewComponent } from './../../../../modals/modal-billet-review/modal-billet-review.component';
import { CurrencyPipe, DatePipe } from '@angular/common';
import { ChangeDetectorRef, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import * as moment from 'moment';


import { AccountPaymentRechargeBilletResponse, AccountRechargeService } from '@wlp/ui-bs-cashin';
import { BilletsListDataFilterPipe } from './billet-list-data-filter.pipe';
import {LoadingService} from "../../../../../../../core/layout/services/loading.service";
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-billets-list',
  templateUrl: './billets-list.page.html',
  styleUrls: ['./billets-list.page.scss'],
  providers: [BilletsListDataFilterPipe, CurrencyPipe],
})
export class BilletsListPage implements OnInit {

  public userProfile: UserInfoProfile;
  private tpVoucher: string;

  @ViewChild(`groupPagination`, { static: false }) groupPagination: ElementRef;
  @ViewChild(`btnList`, { static: false }) btnList: ElementRef;

  // lista principal com os dados da tabela
  public listReceipts: Array<AccountPaymentRechargeBilletResponse>;
  public listReceiptsShow: Array<AccountPaymentRechargeBilletResponse>;
  public scroillTimes: number;

  public inputFilter: string;
  public currentPage: number;
  public dataPerPage: number;
  public maxData: number;

  public flagDateFilter: boolean;

  // datas do calendario
  public calInitialDate: Date;
  public calEndDate: Date;

  // datas do filtro
  public filterInitialDate: Date;
  public filterEndDate: Date;

  // Data inicial em dias anteriores
  private INITIAL_DATE = 7;

  // flag esconde botoes (excel e pdf)
  public flagHideBTNS = false;

  // controle de cores para os botoes de periodos
  public btnColor: string;

  secundary: boolean

  constructor(
    public pipeFilter: BilletsListDataFilterPipe,
    public pipeCurency: CurrencyPipe,
    public detection: ChangeDetectorRef,
    private accountRechargeService: AccountRechargeService,
    protected userInfoService: UserInfoService,
    public modalController: ModalController,
    public activatedRoute: ActivatedRoute,
    private cdr: ChangeDetectorRef,
    private loadingService: LoadingService,
    private storage: Storage
  ) {
    // super(pipeFilter, detection, modalController);

    this.listReceipts = [];
    this.listReceiptsShow = [];
    this.inputFilter = '';

    this.currentPage = 1;
    this.dataPerPage = 10;
    this.maxData = 0;

    this.flagDateFilter = false;

    this.calInitialDate = moment(new Date()).subtract(this.INITIAL_DATE, 'days').toDate();
    this.calEndDate = new Date();
    this.filterInitialDate = null;
    this.filterEndDate = null;

    moment.locale('pt-br');
  }

  ngOnInit() {
    this.loadUser();
  }

  loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then(user => {
        this.userProfile = user;
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  ionViewWillEnter() {
    this.listReceipts = [];
    this.getBillets();
    this.checkViewBalanceSecundaryUser();
  }

  async checkViewBalanceSecundaryUser(){
    await this.storage.get('permissions')
    .then(res => {
      console.log(res)
      this.secundary = res[0] === '*' ? false : true
    })
  }

  private getBillets(initial?: Date) {
    this.loadingService.show();
    if (initial === undefined) {
      initial = this.calInitialDate;
    }
    const dateInitial = new DatePipe('en-US').transform(this.formattedDate(initial), 'yyyy-MM-dd');
    const dateEnd = new DatePipe('en-US').transform(this.formattedDate(this.calEndDate), 'yyyy-MM-dd');

    this.accountRechargeService.getBilletsByUuidAccountPaymentAndPeriod(this.userProfile.uuidAccount, dateInitial, dateEnd)
      .subscribe(
        (res) => {
          this.listReceipts = res;
          // console.log('lista de boletos', this.listReceipts);

          // atualiza o front da tabela
          this.setArrayForShowReceipts(true);
          this.calcPaginationReceipts();
        },
        (err) => {
          console.error(err)
          this.loadingService.hide();
        },() => {
          this.loadingService.hide();
          this.cdr.detectChanges();
        }
      );

  }

  checkStatus(status) {
    if (status) {
      return "Vencido"
    } else {
      return "Válido"
    }
  }

  getTransaction(transactionId: string) {
    this.accountRechargeService.getBilletsByTransactionId(transactionId).subscribe(
      (res) => {

        this.showExtract(res);
      },
    );
  }

  async showExtract(data: AccountPaymentRechargeBilletResponse) {
    const modal = await this.modalController.create({
      component: ModalBilletReviewComponent,
      cssClass: 'modal-review-notification',
      componentProps: {
        billetRequest: false,
        data: data,
      },
      backdropDismiss: false,
    });
    return await modal.present();
  }



  infiniteScrollReceipts(event: Event) {
    const holder: HTMLElement = (event.currentTarget as HTMLElement);
    if (holder.scrollHeight - holder.scrollTop === holder.clientHeight) {
      if (this.currentPage < this.scroillTimes) {
        this.currentPage += 1;
        this.setArrayForShowReceipts();
      }
    }
  }

  public filterByDayReceipts(event: Event, days?: number) {
    this.resetBtnFilters(); // reseta os botoes

    // aplica o estilo para o botao clicado
    const element: HTMLElement = (event.target as HTMLElement);
    element.classList.add('primary');

    // caso for por date
    if (days !== undefined) {
      const date = this.getDatePeriodFilter(new Date(), days);
      this.getBillets(date);

      // toggle para o botao de perido
      this.flagDateFilter = false;
      return;
    }

    // caso for selecionar o periodo (apresenta os campos de periodo)
    this.toggleDateFilter();
  }

  public resetBtnFilters() {
    const btnsHolder: HTMLElement = (this.btnList.nativeElement as HTMLElement);
    const btns: NodeListOf<HTMLElement> = btnsHolder.querySelectorAll('.btn-filter');
    btns.forEach(btn => btn.classList.remove('primary'));
  }

  public filterByDateReceipts() {
    if (this.filterInitialDate === null || this.filterEndDate === null) return;
    // aplica as datas aos filtros
    this.calInitialDate = this.filterInitialDate;
    this.calEndDate = this.filterEndDate;
    this.getBillets(); // chama o metodo de busca por data
  }

  private formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length === 6) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], (date[1] - 1), date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }
    return date;
  }


  public toggleDateFilter() {
    this.flagDateFilter = true;
    this.detection.detectChanges();
  }

  /**
   * responde ao evento de selecao de data do calendario
   * @param dateSelected
   * @param status
   */
  public onSelectedDate(dateSelected: moment.Moment, status: string): boolean {
    if (dateSelected === undefined) return;

    const date = dateSelected.toDate();
    if (status === 'initial') {
      this.filterInitialDate = date;
      return;
    }

    this.filterEndDate = date;
    return;
  }

  /**
   * apresentacao da data na tabela
   * @param date
   */
  public dateControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  public calcPaginationReceipts() {
    this.maxData = this.listReceipts.length;
    let paginationSize = this.maxData / this.dataPerPage;

    if (Number(paginationSize) === paginationSize && paginationSize % 1 !== 0) {
      paginationSize = Math.floor(paginationSize) + 1;
    }

    this.scroillTimes = paginationSize;
  }

  /**
 * set de array que deve ser apresentado por cada pagina
 */
  public setArrayForShowReceipts(isNewSearch?: boolean) {
    if (isNewSearch) {
      this.listReceiptsShow = [];
    }

    for (let i = ((this.currentPage - 1) * this.dataPerPage);
      i < (this.currentPage * this.dataPerPage); i++) {
      this.listReceiptsShow.push(this.listReceipts[i]);
    }
  }

  public filterReceipt(value) {
    if (value !== '') {
      this.listReceiptsShow = this.pipeFilter.transform(this.listReceipts, value);
      return;
    }

    // atualiza o front da tabela
    this.setArrayForShowReceipts(true);
    this.calcPaginationReceipts();
  }

  transform(data: any[], search) {
    if (!search) return data;

    return data.filter(info => {
      let text = info.nameDestination.toLowerCase();

      if (info.accountDestination !== null) {
        text = `${info.accountDestination.toLowerCase()} ${text}`;
      }

      return text.indexOf(search.toLowerCase()) > -1;
    });
  }

  getDatePeriodFilter(currentDate: Date, periodDays: number): Date {
    return new Date(currentDate.setDate(currentDate.getDate() - periodDays));
  }
}
