import { Component, OnInit, ViewChild } from '@angular/core';
import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NavController } from '@ionic/angular';
import { Storage } from '@ionic/storage';
import { QrCodeService, UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { CountdownComponent, CountdownEvent } from 'ngx-countdown';
import { interval, of, Subject, Subscription } from 'rxjs';
import { catchError, flatMap, retry, startWith, takeUntil, tap } from 'rxjs/operators';
import { QrCodeAuthenticationGuard } from 'src/app/core/auth/qrcode-authentication-guard';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-validate-qrcode',
  templateUrl: './validate-qrcode.page.html',
  styleUrls: ['./validate-qrcode.page.scss'],
})
export class ValidateQrcodePage implements OnInit {
  @ViewChild('cd', { static: false }) private countdown: CountdownComponent;

  private destroy$: Subject<null> = new Subject();

  public brand: string;
  public background: string;
  public brandFooter: string;
  public showSocial: boolean;
  public socialYouTube: string;
  public socialFacebook: string;
  public socialLinkedin: string;
  public socialInstagram: string;
  public appleStoreImageLink: string;
  public googleStoreImageLink: string;
  public appleStoreImage: boolean;
  public googleStoreImage: boolean;
  public isMobile: boolean;
  public env: any;
  public wlName: string;
  qrCodeValue: string;
  userProfile: UserInfoProfile;
  progressbarValue = 60;
  timeExpiry: string;
  config: any;
  sub: Subscription;
  subCheckInterval: any;
  done = 0;

  intervalLocation;

  constructor(
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private qrCodeService: QrCodeService,
    private navCtrl: NavController,
    private storage: QrCodeAuthenticationGuard,
    private geolocation: Geolocation
  ) {
    this.wlName = environment.wlThemeParameterization.wlTheme.wlName.toLowerCase();
    this.brand = this.layoutConfigService.getConfig('wlTheme.intro.brand.logo');
    this.showSocial = this.layoutConfigService.getConfig('wlTheme.intro.social.show');
    this.brandFooter = this.layoutConfigService.getConfig('wlTheme.intro.footer.brand');
    this.background = this.layoutConfigService.getConfig('wlTheme.intro.background.image');

    this.socialYouTube = this.layoutConfigService.getConfig('wlTheme.custom.social.youtube');
    this.socialFacebook = this.layoutConfigService.getConfig('wlTheme.custom.social.facebook');
    this.socialLinkedin = this.layoutConfigService.getConfig('wlTheme.custom.social.linkedin');
    this.socialInstagram = this.layoutConfigService.getConfig('wlTheme.custom.social.instagram');

    this.appleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.visible');
    this.appleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.apple.link');

    this.googleStoreImage = this.layoutConfigService.getConfig('wlTheme.storeImage.google.visible');
    this.googleStoreImageLink = this.layoutConfigService.getConfig('wlTheme.storeImage.google.link');

    this.env = environment.production;
  }

  async ngOnInit() {
    this.loadUser();
  }

  async ngOnDestroy() {
    try {
      this.destroyCheckInterval();
    }catch{
    }
    try {
      this.sub.unsubscribe();
    }catch{
    }
    this.destroy$.next();
  }

  destroyCheckInterval() {
    try {
      this.subCheckInterval.unsubscribe();
    }catch{
    }
  }

  async ionViewWillEnter() {
    await this.storage.setItem('validaQrCode', false);
    this.startProgress(this.timeExpiry);
    this.getStatusLocation();
  }

  async getLocation() {
    await this.geolocation
      .getCurrentPosition()
      .then((resp) => {
        if(resp) {
          const location = resp.coords
        this.qrCodeGenerate(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, location);
        this.intervalLocation.unsubscribe();
        }
      })
      .catch((error) => {
        //console.log('Erro ao pegar localização: ', error);
      });
  }

  private loadUser() {
    const userDetails = this.userInfoService.getInfoProfile();
    userDetails.then((user) => {
      this.userProfile = user;
    });
  }

  async qrCodeGenerate(uuidWhiteLabel, uuidAccount, location) {
    this.qrCodeService
      .generateQrCode(uuidWhiteLabel, uuidAccount, location)
      .pipe(
        tap((data) => {
          this.qrCodeValue = data.qrCode;
          this.timeExpiry = data.timeExpiry;

          this.config = {
            leftTime: this.timeExpiry,
          };
          this.getStatus();
        (error) => {}
      }))
      .subscribe(
      );
  }

  private getStatus() {
    const data = {
      qrCode: this.qrCodeValue,
    };

    this.subCheckInterval = interval(3000)
      .pipe(
        takeUntil(this.destroy$),
        startWith(0),
        flatMap(() => this.qrCodeService.checkAccessStatus(data))
      )
      .subscribe(async (data: any) => {
        if (data.status.toUpperCase() === 'ALLOWED' || this.userInfoService.isUserInRole('qrcode-bypass')) {
          try {
            this.destroyCheckInterval();
          }catch{
          }
          await this.storage.setItem('validaQrCode', true);
          sessionStorage.setItem('uuidAccountPaymentDevice', data.uuidAccountPaymentDeviceAuthorizer);
          this.navCtrl.navigateRoot(['/app/dashboard']);
        }
      });
  }

  private getStatusLocation() {
    this.intervalLocation = interval(3000)
      .pipe(
        takeUntil(this.destroy$),
        startWith(0),
        flatMap(() => this.getLocation())
      )
      .subscribe(async (data) => {});
  }

  startProgress(time) {
    const timer$ = interval(360);
    this.sub = timer$.subscribe((sec) => {
      this.progressbarValue = 1 - (sec * 0.37) / time;
      if (this.progressbarValue === 0) {
        this.sub.unsubscribe();
      }
    });
  }

  public onLogout() {
    let redirectUrl = document.location.protocol + '//' + document.location.hostname;

    if (document.location.hostname.includes('localhost')) {
      redirectUrl += ':' + document.location.port;
    }

    this.userInfoService.onLogout(redirectUrl);
  }

  countdownEvent(event) {
    if (event.action === 'done') {
        this.onLogout();
    }
  }
}
