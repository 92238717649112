import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, OnInit } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { AlertController, ModalController, NavController } from '@ionic/angular';
import {
  AccountPaymentTypeResponse,
  AccountTypeService,
  CreateAccountService,
  IPageBase,
  PreAccountResponse,
} from '@wlp/ui-bs-signup';

import { environment } from '../../../../../../../../environments/environment';
import { ModalFlowComponent } from '../../../../modals/modal-flow/modal-flow.component';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-create-account',
  templateUrl: './create-account.page.html',
  styleUrls: ['./create-account.page.scss'],
})
export class CreateAccountPage implements IPageBase, OnInit {
  formCreateAccount: FormGroup;
  errorMessages: any;
  brand: string;
  navigationBackground: string;
  param: object;
  envWlp: any;
  public listAccountType: AccountPaymentTypeResponse[];
  public amountDailyLimit: number;
  public amountThirtyLimit: number;
  public valueDailyLimit: number;
  public valueThirtyLimit: number;

  constructor(
    private navCtrl: NavController,
    public alertController: AlertController,
    private modalController: ModalController,
    public layoutConfigService: LayoutConfigService,
    private createAccountService: CreateAccountService,
    private accountTypeService: AccountTypeService,
    private storage: Storage
  ) {
    this.errorMessages = this.createAccountService.getFormMessageError();
    this.formCreateAccount = this.createAccountService.createForm();
    this.createAccountService.updateForm();
    this.brand = this.layoutConfigService.getConfig('wlTheme.timeline.brand.logo');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
    this.param = { wlName: this.layoutConfigService.getConfig('wlTheme.wlName') };
    this.envWlp = environment;
  }

  ngOnInit() {
    this.loadAccountType();
  }

  ionViewWillEnter() {
    this.formCreateAccount.reset();
  }

  async continue(route?: string): Promise<void> {
    const cpfValue = this.formCreateAccount.get('cpf').value;
    const emailValue = this.formCreateAccount.get('email').value;
    const idValue = await this.createAccountService.getLoadKeyPreAccount().then((keyID) => {
      return keyID;
    });

    sessionStorage.setItem('cpfValue', cpfValue)
    sessionStorage.setItem('emailValue', emailValue)

    this.createAccountService.verifyEmailByIdwl(emailValue, environment.wlp).subscribe(async (resp: any) => {
      if (resp && resp.length > 0) {
        if (resp.addressData && resp.addressData.cep) {
          sessionStorage.setItem('zipCodeValue', resp.addressData.cep);
        }
          if (resp.personData && resp.personData.birthDay) {
            sessionStorage.setItem('birthDateValue', resp.personData.birthDay.replace(/-/g, ""));
          }

        if (
          resp.length === 1 &&
          emailValue === resp[0].email &&
          cpfValue === resp[0].cpf &&
          this.createAccountService.getLoadKeyPreAccount() &&
          idValue === resp[0]._id
        ) {
          this.saveAccount(route);
        } else {
          this.warningEmail();
          this.formCreateAccount.get('email').setErrors({ required: true });
          this.formCreateAccount.get('emailConfirm').setErrors({ mustMatch: true });
        }
      } else {
        this.saveAccount(route);
      }
    });
  }

  saveAccount(route?: string) {
    this.createAccountService
      .createPreAccount(this.formCreateAccount, environment.wlp, route || 'account-type')
      .subscribe(
        async (res: PreAccountResponse) => {
          if(res){
            sessionStorage.setItem('preAccountData', res.uuidWhiteLabel)
            sessionStorage.setItem('preAccountValue', res._id)
            this.createAccountService.processPreAccount(res)
          }
          this.createAccountService.processPreAccount(res);
          this.accountTypeService.getTemporaryTypeAccount().then((accountTypeTemp) => {
            const accountType = this.listAccountType.filter((type) => {
              return type.typeFlow.toString() === accountTypeTemp;
            })[0];

            this.accountTypeService.defineTypeAccount(accountType, 'account-type');
          });
        },
        (err) => this.createAccountService.getErrorPreAccount(err),
        () => this.navCtrl.navigateRoot(route || 'account-type')
      );
  }

  goBack() {
    this.navCtrl.navigateForward('/');
  }

  checkCpf(data: any) {
    this.createAccountService.checkAccountExists(environment.wlp, data.target.value).subscribe(async (resp) => {
      sessionStorage.setItem('accounts', JSON.stringify(resp))
      if (resp && resp.length > 0) {
        if (resp.length === 1 && resp[0].status === 'REPROVED') {
          this.createAccountService.processPreAccount(resp[0]);
          this.navCtrl.navigateRoot('status-account');
        } else {
          await this.modalFlow(resp);
        }
      }
    });
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  checkValues() {
    let cpfValue = this.formCreateAccount.get('cpf').value;
    let emailValue = this.formCreateAccount.get('email').value;
    let emailConfirm = this.formCreateAccount.get('emailConfirm').value

    if(cpfValue && emailValue === emailConfirm && emailValue){
      return false
    } else {
      return true
    }
  }

  async modalFlow(response: any) {
    const modal = await this.modalController.create({
      component: ModalFlowComponent,
      cssClass: 'modal-flow-container',
      componentProps: { response },
      backdropDismiss: false,
    });
    await modal.present();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadAccountType(): void {
    this.accountTypeService
      .loadTypeAccount(environment.wlp)
      .then((list) => (this.listAccountType = list))
      .catch((err) => this.accountTypeService.getErrorMessages(err));
  }

  async warningEmail() {
    const alert = await this.alertController.create({
      cssClass: 'alert-warning-email',
      header: 'Atenção',
      message: 'Esse email já foi associado com outra conta, por favor, informe outro email válido.',
      buttons: ['OK'],
      backdropDismiss: false,
    });

    await alert.present();
  }
}
