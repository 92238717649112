import { Component } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { AccountBalanceService, StatementService } from '@wlp/ui-bs-cashin';
import { ClientLimitRateService } from '@wlp/ui-bs-cashout';
import { QrCodeService, UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { PrePixService } from '@wlp/ui-bs-pre-pix';
import { UICHeaderUserInfoService } from '@wlp/ui-c-header-user-info';
import { UiCModalLimitRateComponent } from '@wlp/ui-c-modal-limit-rate';
import { UiCPixAdvertisementModalComponent } from '@wlp/ui-c-pix-advertisement-modal';
import { catchError, tap } from 'rxjs/operators';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { environment } from 'src/environments/environment';

import { NotificationsPage } from '../notifications/notifications.page';

import { Device } from '@awesome-cordova-plugins/device/ngx';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage';

@Component({
  selector: 'ui-t-dashboard',
  templateUrl: './dashboard.page.html',
  styleUrls: ['./dashboard.page.scss'],
})
export class DashboardPage {
  realm: string;
  modalPixBackgroundImage: string;
  balanceSubject$: any;
  balance: string;
  balanceResponse: any;
  userResponse: any;
  nameInitials: string;
  flagDisplayCard: boolean;
  flagDisplayNotification: boolean;
  listExtract: any;
  userProfile: UserInfoProfile;
  slideSquare: object;
  slideFull: object;
  reloadIcon: boolean;
  reloadIconBalance: boolean;
  qrCodeValue: string;
  secundary: boolean;
  constructor(
    private navCtrl: NavController,
    private modalController: ModalController,
    private accountBalanceService: AccountBalanceService,
    private statementService: StatementService,
    private headerInfoService: UICHeaderUserInfoService,
    private layoutConfigService: LayoutConfigService,
    protected userInfoService: UserInfoService,
    private qrCodeService: QrCodeService,
    private prePixService: PrePixService,
    private clientLimitRate: ClientLimitRateService,
    private device: Device,
    private platform: Platform,
    private storage: Storage
  ) {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = false;
    this.slideSquare = this.layoutConfigService.getImagesPages('swiper-square', true);
    this.slideFull = this.layoutConfigService.getImagesPages('swiper-full', true);
    this.modalPixBackgroundImage = this.layoutConfigService.getConfig('wlTheme.header.background.image');
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
  }

  private loadUser() {
    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        //console.log(user)

        // this.qrCodeService
        //   .generateQrCode(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
        //   .pipe(tap((data) => {}))
        //   .subscribe(
        //     (response) => {
        //       this.qrCodeValue = response.qrCode;
        //     },
        //     (error) => {
        //       //console.log('Erro ao gerar qrCode', error);
        //     }
        //   );
        this.loadStatement(user.uuidWhiteLabel, user.uuidAccount);
        const localStorageValue = JSON.parse(localStorage.getItem('notifications'));
        if (!localStorageValue) {
          this.loadClientLimitRate(user.uuidWhiteLabel, user.uuidAccount);
        }
        /* this.loadPixAdvertisementModal(user) */
      });
    } catch (e) {
      //console.log('Failed to load user details', e);
    }
  }

  loadPixAdvertisementModal(userProfile) {
    this.prePixService.getPrePix(userProfile.uuidWhiteLabel, userProfile.uuidAccount).subscribe((prePixRequest) => {
      if (!prePixRequest.user) {
        this.pixAdvertisementModal();
      }
    });
  }

  async pixAdvertisementModal() {
    const modal = await this.modalController.create({
      component: UiCPixAdvertisementModalComponent,
      cssClass: 'ui-c-pix-advertisement-modal',
      componentProps: {
        backgroundImage: this.modalPixBackgroundImage,
        pixRoute: 'app/pix',
      },
    });
    return await modal.present();
  }

  private loadStatement(uuidWL: string, uuidAccount: string): void {
    this.statementService
      .getLatestTransaction(uuidWL, uuidAccount, 4)
      .then((res) => {
        this.listExtract = res;
        setTimeout(() => {
          this.reloadIcon = false;
        }, 2000);
      })
      .catch((err) => this.accountBalanceService.getErroMessage(err));
  }

  async ionViewWillEnter() {
    this.loadUser();
    this.headerInfoService.updateValue(true);
    if(this.realm === 'tambasafinanceira'){
      this.slideFull[1].url = 'https://tambasafinanceira.com.br/seguros'
      this.slideFull[2].url = 'https://tambasafinanceira.com.br/simulacao-pessoa-juridica/'
    }

  }

  async ionViewDidEnter() {
    setTimeout(async () => {  await this.checkViewBalanceSecundaryUser() }, 1000);
  }

  onFlagActive() {
    this.flagDisplayNotification = false;
    this.flagDisplayCard = this.flagDisplayCard ? false : true;
  }

  async checkViewBalanceSecundaryUser(){
    await this.storage.get('permissions')
    .then(res => {
      if(res){
        this.secundary = res[0] === '*' ? false : true
      }
    })
  }

  onFlagActiveNotification() {
    this.flagDisplayCard = false;
    this.flagDisplayNotification = this.flagDisplayNotification ? false : true;
  }

  iconGrid() {
    this.navCtrl.navigateRoot('#');
  }

  async showNotifications() {
    const modal = await this.modalController.create({
      component: NotificationsPage,
      cssClass: 'modal-custom-class',
    });
    return await modal.present();
  }

  getInitial(name) {
    const parts = name.split(' ');
    let initials = '';
    for (let i = 0; i < 2; i++) {
      if (parts[i].length > 0 && parts[i] !== '') {
        initials += parts[i][0];
      }
    }
    return initials;
  }

  checkValue(value: number): boolean {
    return value > 0 ? true : false;
  }

  excerptText(text: string): string {
    return `${text.substr(0, 25)} ...`;
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  public isVisibleBox() {
    return !environment.production;
  }

  // show box CARTAO PRE PAGO - PEÇA AGORA
  public isVisibleCardBox() {
    return (
      this.realm === 'psxbank' ||
      this.realm === 'logbank' ||
      this.realm === 'londrina' ||
      this.realm === 'avaibank' ||
      this.realm === 'sabank' ||
      this.realm === 'flybank' ||
      this.realm === 'urbanobank' ||
      this.realm === 'okbank' ||
      this.realm === '360bank' ||
      this.realm === 'bpago' ||
      this.realm === 'bullbank' ||
      this.realm === 'fcbank' ||
      this.realm === 'kfpay' ||
      this.realm === 'karebank' ||
      this.realm === 'mkpay' ||
      this.realm === 'plusnetworkbank' ||
      this.realm === 'premierbank' ||
      this.realm === 'sccbank' ||
      this.realm === 'tradepar' ||
      this.realm === 'prospera' ||
      this.realm === 'bansaude' ||
      this.realm === 'fmoney' ||
      this.realm === 'popibank' ||
      this.realm === 'kapitolbank' ||
      this.realm === 'r4bank' ||
      this.realm === 'iosanbank' ||
      this.realm === 'ccmaisbank' ||
      this.realm === 'begbank' ||
      this.realm === 'wibbank' ||
      this.realm === 'saferbank' ||
      this.realm === 'rttbank' ||
      this.realm === 'offerbank' ||
      this.realm === 'ecommercebank' ||
      this.realm === 'popbank' ||
      this.realm === 'umbank' ||
      this.realm === 'feelbank' ||
      this.realm === 'whbank' ||
      this.realm === 'bombank' ||
      this.realm === 'grandbank' ||
      this.realm === 'personalbank' ||
      this.realm === 'wastebank' ||
      this.realm === 'tchenetbank' ||
      this.realm === 'yetbank' ||
      this.realm === 'exitobank' ||
      this.realm === 'keepbank' ||
      this.realm === 'dinoubank' ||
      this.realm === 'anjobank' ||
      this.realm === 'aceitedigital' ||
      this.realm === 'facilitibank' ||
      this.realm === '5gbank' ||
      this.realm === 'imedicalbank' ||
      this.realm === 'bridgebank' ||
      this.realm === 'ativabank' ||
      this.realm === 'h2bank' ||
      this.realm === 'openmeibank' ||
      this.realm === 'bancomin' ||
      this.realm === 'ecosolarbank' ||
      this.realm === 'gxbank' ||
      this.realm === 'bvmbank' ||
      this.realm === 'ammobank' ||
      this.realm === 'flixpay' ||
      this.realm === 'autocred' ||
      this.realm === 'contabilbank' ||
      this.realm === 'rentalizebank' ||
      this.realm === 'rcdigital' ||
      this.realm === '5sbank' ||
      this.realm === 'razonetbank' ||
      this.realm === 'vobisbank' ||
      this.realm === 'razonetbank' ||
      this.realm === 'quality' ||
      this.realm === 'ladysbank' ||
      this.realm === 'hcommercebank' ||
      this.realm === 'mercorabank' ||
      this.realm === 'slipay' ||
      this.realm === 'everabank' ||
      this.realm === 'acebank' ||
      this.realm === 'apcbank' ||
      this.realm === 'db9digitalbank' ||
      this.realm === 'tambasafinanceira' ||
      this.realm === 'multiplybank' ||
      this.realm === 'mexbank' ||
      (this.realm === 'parabank' && this.userInfoService.isUserInRole('gtw-card')) ||
      (this.realm === 'conceptfinancial' && this.userInfoService.isUserInRole('gtw-card')) || // REMOVE WHEN ROLLOUT
      (this.realm === 'spfcbank' && this.userInfoService.isUserInRole('gtw-card')) || // REMOVE WHEN ROLLOUT
      (this.realm === 'tlgdigital' && this.userInfoService.isUserInRole('gtw-card')) || // REMOVE WHEN ROLLOUT
      (this.realm === 'sejabank' && this.userInfoService.isUserInRole('gtw-card')) || // REMOVE WHEN ROLLOUT
      (this.realm === 'eletrobank' && this.userInfoService.isUserInRole('gtw-card')) || // REMOVE WHEN ROLLOUT
      (this.realm === 'seniorbank' && this.userInfoService.isUserInRole('gtw-card')) || // REMOVE WHEN ROLLOUT
      (this.realm === 'veribank' && this.userInfoService.isUserInRole('gtw-card'))
    );
  }

  // show box VENDAS - ADQUIRA NOSSAS SOLUCOES
  public isVisibleCardBoxProducts() {
    return (
      this.realm === '5gbank' ||
      this.realm === '360bank' ||
      this.realm === 'avaibank' ||
      this.realm === 'begbank' ||
      this.realm === 'bombank' ||
      this.realm === 'bpago' ||
      this.realm === 'bridgebank' ||
      this.realm === 'bullbank' ||
      this.realm === 'ccmaisbank' ||
      this.realm === '01bank' ||
      this.realm === 'ecommercebank' ||
      this.realm === 'feelbank' ||
      this.realm === 'flybank' ||
      this.realm === 'fmoney' ||
      this.realm === 'gpbank' ||
      this.realm === 'grandbank' ||
      this.realm === 'imedicalbank' ||
      this.realm === 'imperatriz' ||
      this.realm === 'invbank' ||
      this.realm === 'iosan' ||
      this.realm === 'kapitolbank' ||
      this.realm === 'karebank' ||
      this.realm === 'kfbank' ||
      this.realm === 'logbank' ||
      this.realm === 'londrina' ||
      this.realm === 'mkpay' ||
      this.realm === 'okbank' ||
      this.realm === 'personalbank' ||
      this.realm === 'plusnetwork' ||
      this.realm === 'popbank' ||
      this.realm === 'popibank' ||
      this.realm === 'premierbank' ||
      this.realm === 'prospera' ||
      this.realm === 'psxbank' ||
      this.realm === 'qualitydigitalbank' ||
      // this.realm === 'r4bank' ||
      this.realm === 'rentabank' ||
      this.realm === 'reunibank' ||
      this.realm === 'rttbank' ||
      this.realm === 'santabank' ||
      this.realm === 'sccbank' ||
      this.realm === 'semaperto' ||
      this.realm === 'sevenbank' ||
      this.realm === 'shekelbank' ||
      this.realm === 'tradepar' ||
      this.realm === 'umbank' ||
      this.realm === 'whbank' ||
      this.realm === 'wibbank' ||
      this.realm === 'yetbank' ||
      this.realm === 'delabank'
    );
  }

  reloadStatmentByClick() {
    this.reloadIcon = true;

    if (this.userProfile) {
      this.loadStatement(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);
    }
  }

  public async modalLimitRate(response: any) {
    const modal = await this.modalController.create({
      component: UiCModalLimitRateComponent,
      cssClass: 'ui-c-modal-limit-rate',
      componentProps: { response },
      backdropDismiss: false,
    });
    return await modal.present();
  }

  public loadClientLimitRate(uuidWl: string, uuidAccountPayment: string) {
    this.clientLimitRate.getClientLimitRate(uuidWl, uuidAccountPayment).subscribe((arg) => {
      this.modalLimitRate(arg);
    });
  }
}
