import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';

import { Component, Input } from '@angular/core';
import { ModalController, ToastController } from '@ionic/angular';
import { Clipboard } from '@ionic-native/clipboard/ngx';
import { CardsService } from '@wlp/ui-bs-cards';
import { catchError, retry, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalCardNotificationComponent } from '../modal-card-notification/modal-card-notification.component';

@Component({
  selector: 'modal-virtual-card',
  templateUrl: './modal-virtual-card.component.html',
  styleUrls: ['./modal-virtual-card.component.scss'],
})
export class ModalVirtualCardComponent {

  @Input() card: any;
  @Input() uuidWhiteLabel: string
  @Input() uuidAccountPayment: string
  @Input() code: string

  backgroundHeaderLogon: string;
  statusButton: string
  cards = []
  isExpired: boolean

  constructor(
    public modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private clipboard: Clipboard,
    private toastController: ToastController,
    private cardService: CardsService,
    private loadingService: LoadingService,
    ) {

      this.backgroundHeaderLogon = this.layoutConfigService.getConfig('wlTheme.header.background.image');

  }

  async ionViewWillEnter() {
    this.consultVirtualCard()
    
  }

  async consultVirtualCard() {
    let uuidCard: string
    if(this.card.isVirtual === true || this.card.isVirtual === 1){
      uuidCard = this.card.uuid
    } else {
      uuidCard = this.card.virtualCard.uuid
    }
    this.cardService.consultVirtualCard(this.uuidWhiteLabel, this.uuidAccountPayment, uuidCard, this.code)
    .pipe(
      tap((res) => {
       this.cards.push(
        res
      )
      this.isExpired = this.cards[0].isExpired
      this.checkIsExpired()
      this.statusButton = this.cards[0].status
      }),
      catchError((_) => {
        return of(null);
      })
    )
    .subscribe();
  }

  checkIsExpired() {
    // if(this.isExpired === true){
    //   console.log('este cartão esta expirado')
    // } else {
    //   console.log('Cartão válido')
    // }
  }


  blockCard() {

    this.blockCardService(this.cards[0]);

  }

  async blockCardService(card) {
    this.loadingService.show();
    await this.cardService
      .blockCard(this.uuidWhiteLabel, this.uuidAccountPayment, card)
      .then((res) => {
        this.loadingService.hide();
        if (res.statusCode === 400) {
          this.modalCardNotification('solicitation-card', 'Erro ao bloquear o cartão');
        } else {
          this.modalCardNotification('success', 'Bloqueio concluído!');
          this.cards[0].status = 'Bloqueado'
          this.cards[0].cardStatus = 'BLOQUEADO'
          this.statusButton = 'Bloqueado'
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'Erro ao bloquear o cartão');
      });
  }

  unlockCard(card: any) {

    this.unlockService(this.cards[0]);

  }

  async unlockService(card) {
    this.loadingService.show();
    await this.cardService
      .unlockCard(this.uuidWhiteLabel, this.uuidAccountPayment, card)
      .then((res) => {
        this.loadingService.hide();
        if (res.statusCode === 400) {
          this.modalCardNotification('solicitation-card', 'Erro ao desbloquear o cartão');
        } else {
          this.modalCardNotification('success', 'Cartão Desbloqueado!');
          this.cards[0].status = 'Ativo'
          this.cards[0].cardStatus = 'ATIVO'
          this.statusButton = 'Ativo'
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'Erro ao desbloquear o cartão');
      });
  }

  async modalCardNotification(status: string, message: string) {
    const modal = await this.modalController.create({
      component: ModalCardNotificationComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        status,
        message,
      },
    });
    return await modal.present();
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  blockedCard(status: string) {
    if (status === '2' || status === 'Bloqueado') {
      return 'blocked';
    } else {
      return '';
    }
  }

  public statusCard(status: string) {
    if (status === '0' || status === 'Ativo') {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.ACTIVE';
    } else if (status === '1' || status === 'Desativado' || status === 'Cancelado') {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.INACTIVE';
    } else {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.BLOQUED';
    }
  }

  public statusCardIcon(status: string) {
    if (status === '2' || status === 'Bloqueado') {
      return 'lock-closed';
    } else {
      return 'ellipse';
    }
  }

  statusColor(status: string) {
    if (status === '0' || status === 'Ativo') {
      return 'active-color';
    } else if (status === '1' || status === 'Desativado' || status === 'Cancelado') {
      return 'inactive-color';
    } else {
      return 'bloqued-color';
    }
  }

  async copyCardNumber() {
    let cardNumber = this.cards[0].cardNumber
    navigator.clipboard.writeText(cardNumber)
    const toast = await this.toastController.create({
      message: 'Número do cartão copiado',
      duration: 2000
    });
    toast.present();
  }

  formatCardNumber(value) {

      var v = value.replace(/\s+/g, '').replace(/[^0-9]/gi, '')
      var matches = v.match(/\d{4,16}/g);
      var match = matches && matches[0] || ''
      var parts = []
      for (let i=0, len=match.length; i<len; i+=4) {
        parts.push(match.substring(i, i+4))
      }
      if (parts.length) {
        return parts.join(' ')
      } else {
        return value
      }
    
  }

  goBack() {
    this.modalController.dismiss()
  }

}
