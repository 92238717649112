import { CommonModule, CurrencyPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule } from '@ionic/angular';
import { UiBsAlertModule } from '@wlp/ui-bs-alert';
import { UiBsSignupModule } from '@wlp/ui-bs-signup';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAcceptContentModule } from '@wlp/ui-c-accept-content';
import { UiCAddPartnersModule } from '@wlp/ui-c-add-partners';
import { UiCBoxRadiusModule } from '@wlp/ui-c-box-radius';
import { UiCComercialAgentModule } from '@wlp/ui-c-comercial-agent';
import { UiCCompanyAddressModule } from '@wlp/ui-c-company-address';
import { UiCCompanyDataModule } from '@wlp/ui-c-company-data';
import { UiCContainerBrandModule } from '@wlp/ui-c-container-brand';
import { UiCCreateAccountModule } from '@wlp/ui-c-create-account';
import { UiCPartnersModule } from '@wlp/ui-c-partners';
import { UiCPersonalAddressModule } from '@wlp/ui-c-personal-address';
import { UiCPersonalDataModule } from '@wlp/ui-c-personal-data';
import { UiCProfessionalTypeModule } from '@wlp/ui-c-professional-type';
import { UiCTimelineModule } from '@wlp/ui-c-timeline';
import { UiCToggleModule } from '@wlp/ui-c-toggle';
import { UiCUploadFileWebModule } from '@wlp/ui-c-upload-file-web';
import { UiCWarningModule } from '@wlp/ui-c-warning';

import { environment } from '../../../../../../../environments/environment';
import { LoadingService } from '../../../../../../core/layout/services/loading.service';
import { ComponentsModule } from '../../../../../../core/shared/modules/components.module';
import { StepServiceResolver } from '../../../functions/resolver/step-service-resolver';
import { ModalFlowComponent } from '../../../modals/modal-flow/modal-flow.component';
import { AccountTypePage } from './account-type/account-type.page';
import { ChooseDocumentPage } from './choose-document/choose-document.page';
import { ComercialAgentPage } from './comercial-agent/comercial-agent.page';
import { CompanyAddressPage } from './company-address/company-address.page';
import { CompanyDataPage } from './company-data/company-data.page';
import { CompanyDocumentsPage } from './company-documents/company-documents.page';
import { ContractPage } from './contract/contract.page';
import { CreateAccountPage } from './create-account/create-account.page';
import { DocumentTypePage } from './document-type/document-type.page';
import { DocumentsLiberalPage } from './documents-liberal/documents-liberal.page';
import { DocumentsPartnerPage } from './documents-partner/documents-partner.page.html.page';
import { PartnersPage } from './partners/partners.page';
import { PersonalAddressPage } from './personal-address/personal-address.page';
import { PersonalDataPage } from './personal-data/personal-data.page';
import { PrivacyPolicyPage } from './privacy-policy/privacy-policy.page';
import { ProfessionalTypePage } from './professional-type/professional-type.page';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { UploadDocumentsPage } from './upload-documents/upload-documents.page';
import { LayoutConfigService } from "../../../../../../core/layout/services/layout-config.service";
import { InterceptService } from '../../../../../../core/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { BirthDatePage } from './birth-date/birth-date.page';
import { BrMaskerModule } from 'br-mask';
import { QuizAnswerPage } from './quiz-answer/quiz-answer.page';
import { OcrScannerDataPage } from './ocr-scanner-data/ocr-scanner-data.page';

@NgModule({
  imports: [
    FormsModule,
    IonicModule,
    CommonModule,
    UiCToggleModule,
    ComponentsModule,
    UiCAcceptContentModule,
    ReactiveFormsModule,
    SignUpRoutingModule,
    UiCPersonalDataModule,
    UiCCreateAccountModule,
    UiCComercialAgentModule,
    UiCPersonalAddressModule,
    UiCTimelineModule,
    UiCWarningModule,
    UiCCompanyDataModule,
    UiCPartnersModule,
    UiCCompanyAddressModule,
    UiCContainerBrandModule,
    UiCAddPartnersModule,
    UiCBoxRadiusModule,
    UiCProfessionalTypeModule,
    UiBsSignupModule.forRoot({ device: 'WEB', config: environment }),
    UiBsTranslateModule,
    UiCUploadFileWebModule,
    UiBsAlertModule,
    BrMaskerModule
  ],
  declarations: [
    AccountTypePage,
    ChooseDocumentPage,
    ComercialAgentPage,
    CompanyAddressPage,
    CompanyDataPage,
    CompanyDocumentsPage,
    ContractPage,
    CreateAccountPage,
    DocumentTypePage,
    DocumentsPartnerPage,
    DocumentsLiberalPage,
    PartnersPage,
    PersonalAddressPage,
    PersonalDataPage,
    ProfessionalTypePage,
    UploadDocumentsPage,
    PrivacyPolicyPage,
    BirthDatePage,
    QuizAnswerPage,
    OcrScannerDataPage
  ],
  entryComponents: [ModalFlowComponent],
  providers: [
    LayoutConfigService,
    CurrencyPipe,
    LoadingService,
    StepServiceResolver,
    CurrencyPipe,
    LoadingService,
    StepServiceResolver,
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
})
export class SignUpModule { }
