// Angular
import { Injectable } from '@angular/core';
import { environment } from '../../../../../../../environments/environment';
import { HttpClient } from '@angular/common/http';
import { catchError, map } from 'rxjs/operators';
import { of } from 'rxjs';
import { TransactionFileService } from '@wlp/ui-bs-import-files'

interface IImportFileModel {
  agOrigin: string;
  countSuccesses: number;
  ctoOrigin: string;
  dateRegister: Date;
  dateUpdated: Date;
  deleted: string;
  docOrigin: string;
  fileType: string;
  filer: string;
  id: number;
  name: string;
  sequential: string;
  status: string;
  sumSuccesses: number;
  transactionNumber: number;
  uuid: string;
  uuidAccountPayment: string;
  uuidWhiteLabel: string;
  [key: string]: any;
}

@Injectable({
  providedIn: 'root',
})
export class ImportFilesService {
  private url = `${environment.apiUri}/spe-n-import-transaction-files`;

  constructor(
    private httpClient: HttpClient,
    private transactionFile: TransactionFileService
    ) {}

  async getImportFiles(userProfileInfo, filter): Promise<IImportFileModel[]> {
    const url = `${this.url}/v1/transactions-file/token`;
    const options = {
      params: {
        ...filter,
      },
    };

    return new Promise((resolve, reject) => {
      this.httpClient.get(url, options).subscribe(resolve, reject);
    });
  }

  async uploadFile(fileToUpload, codeAuthorization, fileType, uuidAccountPayment): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('transactionFile', fileToUpload, fileToUpload.name);
    formData.append('codeAuthorization', codeAuthorization);
    formData.append('fileType', fileType);

    return new Promise((resolve) => {
     this.transactionFile.uploadFile(formData, uuidAccountPayment)
    .pipe(catchError((_) => of ([])))
    .subscribe(resolve)
    });
  }

  async translateFile(fileToUpload, fileType, uuidAccountPayment): Promise<any> {
    const formData: FormData = new FormData();
    formData.append('transactionFile', fileToUpload, fileToUpload.name);
    formData.append('fileType', fileType);

    return new Promise((resolve) => {
      this.transactionFile.translateFile(formData, uuidAccountPayment)
      .pipe(catchError((_) => of([])))
      .subscribe(resolve);
    });
  }

  async getDownloadLayoutFileCsv(fileType: string) {
    return new Promise((resolve, reject) => {
      this.transactionFile.getDownloadLayoutFileCsv(fileType).subscribe(resolve, reject);
    });
  }

  async getDownloadLayoutFileXlsx(fileType: string) {
    const url = `${this.url}/templates/template_${fileType}.xlsx`;
    return new Promise((resolve, reject) => {
      this.httpClient.get(url, { responseType: 'blob' }).subscribe(resolve, reject);
    });
  }

  getDownloadResumeFile(uuidTransactionFileId, type) {
    return new Promise((resolve, reject) => {
      this.transactionFile.getDownloadResumeFile(uuidTransactionFileId, type).subscribe(resolve, reject);
    });
  }
}
