import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { UiBsCashInModule } from '@wlp/ui-bs-cashin';
import { UiBsCashOutModule } from '@wlp/ui-bs-cashout';
import { UiBsTranslateModule } from '@wlp/ui-bs-translate';
import { UiCAccountBalanceModule } from '@wlp/ui-c-account-balance';
import { UiCAsideMenuLeftModule } from '@wlp/ui-c-aside-menu-left';
import { UiCCardInfoModule } from '@wlp/ui-c-card-info';
import { UiCDatePickerModule } from '@wlp/ui-c-date-picker';
import { HeaderUserInfoModule } from '@wlp/ui-c-header-user-info';
import { UiCModalModule } from '@wlp/ui-c-modal';
import { UiCNotificationsModule } from '@wlp/ui-c-notifications';
import { UiCTransferReceiptModule } from '@wlp/ui-c-transfer-receipt';
import { BrMaskerModule } from 'br-mask';
import { TooltipModule } from 'ng2-tooltip-directive';
import { NgxMaskIonicModule } from 'ngx-mask-ionic';
import { ComponentsModule } from 'src/app/core/shared/modules/components.module';
import { environment } from 'src/environments/environment';

import { ModalsModule } from '../../../../modals/modals.module';
import { ExtractDigitalAccountPage } from './digital-account/extract-digital-account.page';
import { ExtractDataFilterPipe } from './extract-data-filter.pipe';
import { ExtractRoutingModule } from './extract-routing.module';
import { ExtractReceiptPage } from './receipt/extract-receipt.page';
import { InterceptService } from '../../../../../../../core/intercept/intercept.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { SharedModule } from 'src/app/core/shared/shared.module';

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TooltipModule,
    ComponentsModule,
    ReactiveFormsModule,
    NgxMaskIonicModule.forRoot(),
    UiCAccountBalanceModule,
    UiBsCashInModule.forRoot({ device: 'WEB', config: environment }),
    BrMaskerModule,
    UiCAsideMenuLeftModule,
    RouterModule,
    UiCCardInfoModule,
    UiBsTranslateModule,
    UiCNotificationsModule,
    ExtractRoutingModule,
    UiBsCashOutModule,
    UiCDatePickerModule.forRoot({ environment: 'web' }),
    UiCModalModule,
    HeaderUserInfoModule,
    UiCTransferReceiptModule,
    ModalsModule,
    SharedModule,
  ],
  declarations: [ExtractReceiptPage, ExtractDigitalAccountPage, ExtractDataFilterPipe],
  providers: [
    InterceptService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
  ],
  entryComponents: [],
  bootstrap: [],
})
export class ExtractModule {}
