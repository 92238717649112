import { RouterModule, Routes } from '@angular/router';

import { ModalPixManagementKeyComponent } from './modal-pix-management-key/modal-pix-management-key.component';
import { ModalPixComponent } from './modal-pix/modal-pix.component';
import { ModalAccountActivationComponent } from './modal-account-activation/modal-account-activation.component';
import { ModalActiveCardComponent } from './modal-active-card/modal-active-card.component';
import { ModalAlertBilletComponent } from './modal-alert-billet/modal-alert-billet.component';
import { ModalAlertCardComponent } from './modal-alert-card/modal-alert-card.component';
import { ModalAlertComponent } from './modal-alert/modal-alert.component';
import { ModalBilletReviewChargingComponent } from './modal-billet-review-charging/modal-billet-review-charging.component';
import { ModalBilletReviewComponent } from './modal-billet-review/modal-billet-review.component';
import { ModalCardDocumentComponent } from './modal-card-document/modal-card-document.component';
import { ModalCardNotificationComponent } from './modal-card-notification/modal-card-notification.component';
import { ModalExtractReviewComponent } from './modal-extract-review/modal-extract-review.component';
import { ModalOperationNotificationComponent } from './modal-operation-notification/modal-operation-notification.component';
import { ModalProsperaAttendanceComponent } from './modal-prospera-attendance/modal-prospera-attendance.component';
import { ModalProsperaBilletComponent } from './modal-prospera-billet/modal-prospera-billet.component';
import { ModalProsperaNotificationComponent } from './modal-prospera-notification/modal-prospera-notification.component';
import { ModalSearchBankComponent } from './modal-search-bank/modal-search-bank.component';
import { ModalSharePage } from './modal-share/modal-share.page';
import { ModalTransferNotificationAcceptedComponent } from './modal-transfer-notification-accepted/modal-transfer-notification-accepted.component';
import { ModalTransferNotificationCompletedComponent } from './modal-transfer-notification-completed/modal-transfer-notification-completed.component';
import { ModalTransferNotificationRefusedComponent } from './modal-transfer-notification-refused/modal-transfer-notification-refused.component';
import { ModalTransferResumeComponent } from './modal-transfer-resume/modal-transfer-resume.component';
import { ModalUnlockCardAlertComponent } from './modal-unlock-card-alert/modal-unlock-card-alert.component';
import { ModalUnlockCardComponent } from './modal-unlock-card/modal-unlock-card.component';
import { NgModule } from '@angular/core';
import { PopoverFavoritesComponent } from './popover-favorites/popover-favorites.component';
import { PopoverSaveOptionsComponent } from './popover-save-options/popover-save-options.component';
import { ModalPdfGenerateComponent } from './modal-pdf-generate/modal-pdf-generate.component';
import { ModalCsvGenerateComponent } from './modal-csv-generate/modal-csv-generate.component';
import { ModalVirtualCardComponent } from './modal-virtual-card/modal-virtual-card.component';
import { ModalCombateAFraudeComponent } from './modal-combate-a-fraude/modal-combate-a-fraudecomponent';

const routes: Routes = [
  {
    path: 'modal',
    component: ModalAlertComponent,
  },
  {
    path: 'modal-share',
    component: ModalSharePage,
  },
  {
    path: 'modal-billet-review',
    component: ModalBilletReviewComponent,
  },
  {
    path: 'modal-transfer-notification-completed',
    component: ModalTransferNotificationCompletedComponent,
  },
  {
    path: 'modal-transfer-notification-accepted',
    component: ModalTransferNotificationAcceptedComponent,
  },
  {
    path: 'modal-transfer-notification-refused',
    component: ModalTransferNotificationRefusedComponent,
  },
  {
    path: 'modal-transfer-resume',
    component: ModalTransferResumeComponent,
  },
  {
    path: 'modal-operation-notification',
    component: ModalOperationNotificationComponent,
  },
  {
    path: 'modal-search-bank',
    component: ModalSearchBankComponent,
  },
  {
    path: 'modal-extract-review',
    component: ModalExtractReviewComponent,
  },
  {
    path: 'modal-prospera-notification',
    component: ModalProsperaNotificationComponent,
  },
  {
    path: 'modal-prospera-attendance',
    component: ModalProsperaAttendanceComponent,
  },
  {
    path: 'modal-prospera-billet',
    component: ModalProsperaBilletComponent,
  },
  {
    path: 'modal-card-document',
    component: ModalCardDocumentComponent,
  },
  {
    path: 'modal-card-notification',
    component: ModalCardNotificationComponent,
  },
  {
    path: 'modal-active-card',
    component: ModalActiveCardComponent,
  },
  {
    path: 'modal-alert-card',
    component: ModalAlertCardComponent,
  },
  {
    path: 'modal-unlock-card',
    component: ModalUnlockCardComponent,
  },
  {
    path: 'modal-unlock-card-alert',
    component: ModalUnlockCardAlertComponent,
  },
  {
    path: 'modal-billet-review-charging',
    component: ModalBilletReviewChargingComponent,
  },
  {
    path: 'modal-alert-billet',
    component: ModalAlertBilletComponent,
  },
  {
    path: 'modal-account-activation',
    component: ModalAccountActivationComponent,
  },
  {
    path: 'popover-favorites',
    component: PopoverFavoritesComponent,
  },
  {
    path: 'popover-save-options',
    component: PopoverSaveOptionsComponent,
  },
  {
    path: 'modal-pix-management-key',
    component: ModalPixManagementKeyComponent,
  },
  {
    path: 'modal-pix',
    component: ModalPixComponent,
  },
  {
    path: 'modal-pdf-generate',
    component: ModalPdfGenerateComponent,
  },
  {
    path: 'modal-csv-generate',
    component: ModalCsvGenerateComponent,
  },
  {
    path: 'modal-virtual-card',
    component: ModalVirtualCardComponent,
  },
  {
    path: 'modal-combate-a-fraude',
    component: ModalCombateAFraudeComponent,
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ModalsRoutingModule {}
