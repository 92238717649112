export const MENU_ASIDE_PROD = [
  {
    url: '/app/dashboard',
    label: 'Inicio',
    icon: 'home-outline',
  },
  // {
  //   url: '/cashback',
  //   label: 'Cashback',
  //   icon: 'cash-outline',
  // },
  // {
  //   url: '/prosperaenergy/acquire-energy',
  //   label: 'Prospera Energia',
  //   icon: 'sunny-outline',
  // },
  {
    url: [
      { icon: 'wallet-outline', url: '/app/my-data', label: 'Dados cadastrais' },
      // MUDAR O ROTA ASSIM QUE ESTIVER FLUXO COMPLETO
      // { url: '/app/onboarding-ec/comercial-agent-ec', label: 'Pedir conta PJ', requiredPermission: '*' },
      // { url: '/app/onboarding-ec/showcase-ec', label: 'Quero Vender', requiredPermission: '*' },
    ],
    label: 'Meu Perfil',
    icon: 'person-outline',
  },
  /*{
    url: '/app/dashboard',
    label: 'Minhas Vendas',
    icon: 'basket-outline',
  },*/

  {
    url: [
      // { url: '/app/pix/pre-register', label: 'Pré-Cadastro' },
      { url: '/app/pix/manage-keys', label: 'Minhas Chaves', requiredPermission: '*' },
      { url: '/app/pix/payment', label: 'Pagar', requiredPermission: 'pixAccess' },
      { url: '/app/pix/qr-code/create', label: 'Receber', requiredPermission: 'pixAccess' },
    ],
    label: 'Pix',
    src: 'assets/images/pix/simbolo_pix_policromia_positivo.svg',
  },

  {
    url: '/app/extract',
    label: 'Extrato',
    src: 'assets/images/cash-in/Extrato.svg',
    requiredPermission: 'viewBalanceAccess',
  },

  {
    url: '/app/payment/billet',
    label: 'Pagamentos',
    icon: 'barcode-outline',
    requiredPermission: 'billPaymentAccess',
  },
  {
    url: '/app/payment/liquidation',
    label: 'Compensação de Boleto',
    icon: 'calculator-outline',
  },
  {
    url: [
      { url: '/app/charging/billet/emit', label: 'Emitir boleto de cobrança', requiredPermission: '*' },
      { url: '/app/charging/billet/create', label: 'Gerenciar Boletos de Cobrança', requiredPermission: '*' },
      // { url: '/app/charging/link/emit', label: 'Link de Pagamento', requiredPermission: "*" },
      // { url: '/app/charging/link/create', label: 'Gerenciar Links de Pagamento', requiredPermission: "*" },
    ],
    label: 'Cobrança',
    src: 'assets/images/charging/icon-cobranca.svg',
  },
  {
    url: [
      { url: '/app/recharge', label: 'Depósito via boleto', requiredPermission: 'chargeAccountAccess' },
      { url: '/app/list-billets', label: 'Lista de Boletos Gerados', requiredPermission: 'chargeAccountAccess' },
    ],
    label: 'Depósito via boleto',
    src: 'assets/images/cash-in/Cobrar.svg',
  },
  {
    url: '/app/mobile-recharge',
    label: 'Recarga de Celular',
    icon: 'phone-portrait-outline',
    requiredPermission: '*',
  },
  {
    url: [
      {
        url: '/app/extract/receipt/full',
        label: 'Lista Geral de Comprovantes',
        requiredPermission: 'viewBalanceAccess',
      },
      {
        url: '/app/extract/receipt/on-us',
        label: 'Transferências Entre Contas Realizadas',
        requiredPermission: 'viewBalanceAccess',
      },
      { url: '/app/extract/receipt/ted', label: 'TEDs/DOCs Enviados', requiredPermission: 'viewBalanceAccess' },
      {
        url: '/app/extract/receipt/payment',
        label: 'Boletos e Tributos Pagos',
        requiredPermission: 'viewBalanceAccess',
      },
      { url: '/app/extract/receipt/pix', label: 'Pix Enviados', requiredPermission: 'viewBalanceAccess' },
    ],
    label: 'Comprovantes',
    icon: 'receipt-outline',
  },

  /*   {
    url: [
      { url: '/app/cards/card-manager', label: 'Gerenciar' },
      { url: '/app/cards/card-active', label: 'Ativar Novo Cartão' },
    ],
    // url: '/app/cards/card-manager',
    label: 'Meus Cartões',
    icon: 'card-outline',
  }, */
  /*{
    url: [{ url: '/app/dashboard', label: 'Cartões Cadastrados' }],
    label: 'Carteira Digital',
    icon: 'wallet-outline',
  },*/
  /* {
    url: [
      { url: '/app/transfer/document', label: 'Entre Contas' },
      { url: '/app/transfer/other-accounts', label: 'TED para Outros Bancos' },
      // { url: '/app/transfer/duplicate-vouchers', label: 'Comprovantes' },
      // { url: '/app/transfer', label: 'Favoritos' }, // EM BREVE "NOVA TELA"
      // { url: '/app/transfer/lot', label: 'Em Lote (Em breve)' },
    ],
    label: 'Transferências',
    icon: 'swap-horizontal-outline',
  }, */
  {
    url: [
      { url: '/app/charging/transfer/document', label: 'Entre Contas', requiredPermission: 'transferAccess' },
      {
        url: '/app/charging/transfer/other-accounts-favorites',
        label: 'TED para Outros Bancos',
        requiredPermission: 'tedAccess',
      },
      // { url: '/app/transfer/document', label: 'Entre Contas' },
      // { url: '/app/transfer/other-accounts', label: 'TED para Outros Bancos' },
      { url: '/app/import-files', label: 'Em Lote', requiredPermission: '*' },
      { url: '/app/charging/favorites-edit', label: 'Favoritos', requiredPermission: '*' },
    ],
    label: 'Transferências',
    icon: 'swap-horizontal-outline',
  },
  {
    url: '/app/cards/card-manager',
    label: 'Meus Cartões',
    icon: 'wallet-outline',
    requiredPermission: '*',
  },
  {
    url: [
      { url: '/app/permissions', label: 'Lista de usuários', requiredPermission: 'manageUsersAccess' },
      {
        url: '/app/transaction-authorization',
        label: 'Aprovação de Transações',
        requiredPermission: 'approver',
      },
    ],
    label: 'Usuários da conta',
    icon: 'people-outline',
  },
  // {
  //   url: '/app/insurance',
  //   label: 'Seguros',
  //   src: 'assets/images/insurance/Insurance.svg',
  //   requiredPermission: '*',
  // },
  {
    url: '/app/credit',
    label: 'Crédito',
    icon: 'cash-outline',
    requiredPermission: '*',
  },
  // {
  //   url: [
  //     { url: '/app/cards/card-manager', label: 'Gerenciar' },
  //     { url: '/app/cards/card-active', label: 'Ativar Novo Cartão' },
  //   ],
  //   // url: '/app/cards/card-manager',
  //   label: 'Meus Cartões',
  //   icon: 'card-outline',
  // },
  /*
  {
    url: [
      { url: '/app/dashboard', label: 'Configurações Gerais' },
      // { url: '/app/dashboard', label: 'Dispositivos Registrados' },
      // { url: '/app/dashboard', label: 'Segurança' },
    ],
    label: 'Configurações',
    icon: 'settings-outline',
  },*/
];
