import * as moment from 'moment';

import { CardConsultResultDto, CardsService } from '@wlp/ui-bs-cards';
import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ModalController, NavController } from '@ionic/angular';
import { catchError, tap } from 'rxjs/operators';

import { ExtractDigitalAccountService } from '@wlp/ui-bs-cashout';
import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ModalAlertCardComponent } from '../../../../modals/modal-alert-card/modal-alert-card.component';
import { ModalCardDocumentComponent } from '../../../../modals/modal-card-document/modal-card-document.component';
import { ModalCardNotificationComponent } from '../../../../modals/modal-card-notification/modal-card-notification.component';
import { ModalTokenComponent } from '../../../../modals/modal-token/modal-token.component';
import { ModalUnlockCardAlertComponent } from './../../../../modals/modal-unlock-card-alert/modal-unlock-card-alert.component';
import { ModalUnlockCardComponent } from './../../../../modals/modal-unlock-card/modal-unlock-card.component';
import { UiBsMyDataService } from '@wlp/ui-bs-my-data';
import { UiCCardRedefinePasswordComponent } from '@wlp/ui-c-card-redefine-password';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { UserInfoService } from '@wlp/ui-bs-login';
import { environment } from '../../../../../../../../environments/environment';
import { of } from 'rxjs';
import { ValidSmsPage } from '../../cash-out/valid-sms/valid-sms.page';
import { ModalVirtualCardComponent } from '../../../../modals/modal-virtual-card/modal-virtual-card.component';

@Component({
  selector: 'ui-t-card-manager',
  templateUrl: './card-manager.page.html',
  styleUrls: ['./card-manager.page.scss'],
})
export class CardManagerPage implements OnInit {
  private SEVEN_DAYS = 7;
  private userProfile: UserInfoProfile;
  public extractList: any;
  public pageLimit = 10;

  public swButtons = false;
  public allowedVirtualCards: boolean
  public embossCard: boolean
  public cardExpired = false;
  public results: CardConsultResultDto;
  public cards: Array<any>;
  public cardsOrigin: Array<any>;
  public nameCard: any;
  public noCards = true;
  public cardSelected: any;
  public param: object;
  public paramSearch: object;
  public cardRequested = false;
  public cardOrderRequested = false;
  private accountData: any;
  public fontSize: string;
  public isVisibleExtractCart = true; //!environment.production;
  public realm: string;

  constructor(
    protected userInfoService: UserInfoService,
    private cardService: CardsService,
    private myDataService: UiBsMyDataService,
    private loadingService: LoadingService,
    private modalController: ModalController,
    private layoutConfigService: LayoutConfigService,
    private extractDigitalAccountService: ExtractDigitalAccountService,
    private cdr: ChangeDetectorRef,
    private navCtrl: NavController
  ) {
    this.realm = environment.wlThemeParameterization.wlTheme.realm;
  }

  public ngOnInit() {
    this.loadUser();
    this.paramSearch = { searchDay: this.SEVEN_DAYS };

    //REMOVE WHEN ROLLOUT
    if 
      (this.realm === 'conceptfinancial' && !this.userInfoService.isUserInRole('gtw-card')) {
      this.navCtrl.navigateRoot('/app');
    }
  }

  public selectCard(cards: any) {
    this.swButtons = false;
    this.cardSelected = cards;
    this.removeSelected(cards);
    this.cardOrderRequested = cards.currentAccount.cardCheck === null;
    if (this.cardSelected && this.cardSelected.carriersName.split('').length > 25) {
      this.fontSize = '10';
    } else {
      this.fontSize = '12';
    }
    if(this.cardSelected != undefined){
      this.cardExpired = this.cardSelected.isExpired;
      }
  }

  public requestCard() {
    this.loadingService.show();
    this.cardService
      .validateOperation(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount)
      .then((res) => {
        this.loadingService.hide();
        if (!res) {
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
          return;
        } else {
          if (!res.isAllowed) {
            this.modalCardNotification(
              'solicitation-card',
              'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED_NOT_FUNDS'
            );
            return;
          } else {
            if(this.embossCard === true){
            this.modalCardDocument(
              'Solicitação do cartão',
              'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_CONTINUE',
              'CARDS.CARD_MANAGER.CARDS_ACTIVE.BUTTON_CANCEL',
              'request',
              'solicitation-card'
            );
            } else {
              this.modalCardDocument(
                'Solicitação do cartão virtual',
                'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_CONTINUE',
                'CARDS.CARD_MANAGER.CARDS_ACTIVE.BUTTON_CANCEL',
                'virtualRequest',
                'solicitation-card'
              )
            }
          }
        }
        return;
      })
      .catch((err) => {
        this.loadingService.hide();
        this.cardService.getError(err);
        this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
      });
  }

  public activeCartNoName() {
    this.navCtrl.navigateRoot('/app/cards/card-active');
  }

  public disabledRequestCard() {
    if (this.realm === 'sabank') {
      // console.log('Entrou desativar:: true');
      return true;
    } else {
      return this.cardsOrigin && this.cardsOrigin.length > 0;
    }
  }

  disabledRequestCardVirtual() {
    if (this.allowedVirtualCards) {
      return this.allowedVirtualCards
    } 
  }

  public async modalAlertCard(type?: any, message?: string, titleBtn?: string) {
    const modal = await this.modalController.create({
      component: ModalAlertCardComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        titleBtnConfirm: titleBtn,
        alertRequest: type === 'request' ? true : false,
        message,
        param: this.param,
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        switch (type) {
          case 'request': {
            this.checkRequisitionInProgressService(type);
            break;
          }
        }
      }
    });
    return await modal.present();
  }

  public async modalUnlockAlert(card: any) {
    const modal = await this.modalController.create({
      component: ModalUnlockCardAlertComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        titleBtnConfirm: 'CARDS.CARD_MANAGER.CARD_UNLOCK_ALERT.CONTINUE',
        alertRequest: true,
        cardResponse: card,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data.id !== undefined) {
        this.unlockCard(data.data);
      }
    });
    return await modal.present();
  }

  private async checkRequisitionInProgressService(type: string) {
    await this.cardService
      .checkRequisitionInProgress(this.userProfile.uuidAccount, this.accountData.documentDefault)
      .then((res) => {
        // console.log("response check", res);

        if (res.response === true) {
          this.modalCardNotification(
            'solicitation-card',
            'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED_REQUEST_IN_PROGRESS'
          );
        } else if (res.response === false) {
          if(this.embossCard === false) {
            this.token('request')
          } else {
          this.token(type);
          }
        } else {
          console.error('error', res);
          this.token(type);
        }
      })
      .catch((err) => {
        console.error(err);
        this.token(type);
      });
  }

  public getImageTag(tag: string) {
    const item: any = this.layoutConfigService.getImagesPages(tag);
    return item.path;
  }

  private loadUser() {
    const currentDate = new Date();
    const sevenDateAgo = this.getDatePeriodFilter(new Date(), this.SEVEN_DAYS);

    try {
      const userDetails = this.userInfoService.getInfoProfile();
      userDetails.then((user) => {
        this.userProfile = user;
        //console.log("user account", this.userProfile)
        this.loadAcountData(this.userProfile.uuidAccount);
        this.getCards(this.userProfile);
        this.getRequestCardTax(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount);
        this.getExtractByPeriod(user, sevenDateAgo, currentDate);
      });
    } catch (e) {
      console.error('Failed to load user details', e);
    }
  }

  private async getCards(userProfile: UserInfoProfile) {
    this.loadingService.show();

    // busca os dados do cartao
    await this.cardService
      .getCards(userProfile.uuidWhiteLabel, userProfile.uuidAccount)
      .then((res) => this.getCardsResult(res))
      .catch((err) => {
        // se falhar na primeira, deve procurar por status de pedidos na tabela
        this.cardService
          .getCardsStatus(userProfile.uuidAccount)
          .then((res) => this.getCardsResult(res))
          .catch((err) => {
            // se todas falharem...
            this.loadingService.hide();
            console.error(err);
          });
      });
  }

  private getCardsResult(res) {
    this.loadingService.hide();
    // console.log('getCards', res);
    try {
      if (res !== null) {
        this.results = res;
        if (this.results.statusCode === 400) {
          this.noCards = true;
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CONSULT_CARD_ERROR');
          this.cards = [];
        } else {
          console.log('results', this.results);
          const sizeCard = this.results.cards ? this.results.cards.length : 0;
          const sizeAccount = this.results.account ? this.results.account.length : 0;
          this.cardRequested = this.disabledRequestCard();
          if (sizeCard > 0) {
            this.cardsOrigin = [];
            this.allowedVirtualCards = true;
            this.embossCard = false;
            // this.cards = Object.assign([], this.results.cards);
            this.noCards = false;
            //console.log('Log Results.Cards', this.results.cards);
            this.selectCard(this.results.cards[0]);
            this.cards.push({
              requestCard: true,
            });
          } else if (sizeAccount > 0) {
            console.log('sizeAccount', sizeAccount, this.results.account);
            this.cardRequested = this.disabledRequestCard();
            this.cardOrderRequested = true;
            this.noCards = true;
            this.selectCard(this.results.account[0]);
            this.allowedVirtualCards = this.results.allowedVirtualCards;
            this.embossCard = this.results.embossCard;
            // console.log('Log cardRequested', this.cardRequested);
          } else {
            this.noCards = true;
            this.allowedVirtualCards = this.results.allowedVirtualCards;
            this.embossCard = this.results.embossCard;
          }
        }
      } else {
        this.noCards = true;
      }
    } catch (error) {
      this.noCards = true;
    }
  }

  private removeSelected(selected) {
    if (this.cardsOrigin) {
      this.cards = this.cardsOrigin.filter((obj) => !obj.uuid.includes(selected.uuid));
    }
  }

  private async loadAcountData(uuidAccount: string) {
    await this.cardService
      .getAccountDataReduceWithAddress(uuidAccount)
      .pipe(
        tap((data) => {
          this.accountData = data;
        })
      )
      .subscribe();
  }

  private async getRequestCardTax(uuidWhiteLabel, uuidAccount) {
    await this.cardService
      .getCardTax(uuidWhiteLabel, uuidAccount)
      .then((res) => {
        //console.log("teste", res)
        const fomatter = new Intl.NumberFormat('pt-br', {
          style: 'currency',
          currency: 'BRL',
          minimumFractionDigits: 2,
        });
        this.param = { value: fomatter.format(res.value).replace('R$', '') };
      })
      .catch((err) => {
        console.error(err);
      });
  }

  public getVirtualCard(card: any) {
    this.modalCardDocument(
      'Solicitação do cartão virtual',
      'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_CONTINUE',
      'CARDS.CARD_MANAGER.CARDS_ACTIVE.BUTTON_CANCEL',
      'virtualRequest',
      'solicitation-card'
    )
  }

  requestVirtualCard() {
    this.loadingService.show();
    this.cardService
      .requestVirtualCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, this.cardSelected)
      .then((res) => {
        this.loadingService.hide();
        this.modalCardNotification('success', 'Cartão virtual solicitado com sucesso.');
        this.getCards(this.userProfile);
      })
      .catch((error) => {
        this.loadingService.hide();
        this.modalCardNotification('recused', 'Por favor, tente novamente.');
      });
  }

  public myVirtualCard(card: any) {
    this.validSms('virtualRequest')
    // this.goToMyVirtualCard('1111')
  }

  async goToMyVirtualCard(token: string) {
    this.loadingService.hide()

    const modal = await this.modalController.create({
      component: ModalVirtualCardComponent,
      cssClass: 'my-custom-class',
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {
        card: this.cardSelected,
        uuidWhiteLabel: this.userProfile.uuidWhiteLabel,
        uuidAccountPayment: this.userProfile.uuidAccount,
        code: token
      }
    });
    modal.onDidDismiss().then((data) => {
      this.getCards(this.userProfile)
    });

    return await modal.present()

  }

  async validSms(type: string) {
    const modal = await this.modalController.create({
      component: ValidSmsPage,
      cssClass: 'my-custom-class',
      swipeToClose: false,
      backdropDismiss: false,
      componentProps: {
        type: type
      }
    });

      modal.onDidDismiss()
      .then(data => {
        let dataResponse:any = data
         if (type === 'virtualRequest'){
        if ( dataResponse.data[0].validToken == true) {
          this.loadingService.show();
          this.goToMyVirtualCard(dataResponse.data[0].token)
          return;
        } else {
          // this.alertError('Código SMS não validado, tente novamente!')
          return;
        }
        
      }
      })
     this.loadingService.hide();
     return await modal.present();

  }

  public formatNumberCard(numberCard: string) {
    if (numberCard) {
      return numberCard.substr(numberCard.length - 4, 4);
    } else {
      return '';
    }
  }

  public statusCard(status: string) {
    if (status === '0' || status === 'Ativo') {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.ACTIVE';
    } else if (status === '1' || status === 'Desativado' || status === 'Cancelado') {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.INACTIVE';
    } else if (status === 'Em Processamento'){
      return 'CARDS.CARD_MANAGER.STATUS_CARD.PROCESSING'
    } else {
      return 'CARDS.CARD_MANAGER.STATUS_CARD.BLOQUED';
    }
  }

  public statusCardIcon(status: string) {
    if (status === '2' || status === 'Bloqueado' || status === 'Em Processamento') {
      return 'lock-closed';
    } else {
      return 'ellipse';
    }
  }

  public statusColor(status: string) {
    if (status === '0' || status === 'Ativo') {
      return 'active-color';
    } else if (status === '1' || status === 'Desativado' || status === 'Cancelado') {
      return 'inactive-color';
    } else {
      return 'blocked-color';
    }
  }

  public positionCard(index: number) {
    switch (index) {
      case 0: {
        if (this.cards.length === 1) {
          return 'position0';
        } else {
          return '';
        }
      }
      case 1:
        return 'position1';
      case 2:
        return 'position2';
      case 3:
        return 'position3';
      case 4:
        return 'position4';
      case 5:
        return 'position5';
      case 6:
        return 'position6';
      case 7:
        return 'position7';
      case 8:
        return 'position8';
      case 9:
        return 'position9';
      case 10:
        return 'position10';
      case 11:
        return 'position11';
      case 12:
        return 'position12';
    }
  }

  public blockedCard(status: string) {
    if (status === '2' || status === 'Bloqueado') {
      return 'blocked';
    } else {
      return '';
    }
  }

  public detailsOrder(cardRequested) {
    this.modalCardDocument(
      'Detalhe do Pedido',
      'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_CONTINUE',
      null,
      'success',
      null,
      cardRequested
    );
  }

  private async modalCardDocument(
    titleModal: string,
    titleBtn: string,
    titleBtnCancel: string,
    type: string,
    tagImg: string,
    card?: any
  ) {
    const modal = await this.modalController.create({
      component: ModalCardDocumentComponent,
      cssClass: tagImg !== null ? 'modal-card-class' : 'modal-detail-class',
      componentProps: {
        title: titleModal,
        titleBtnConfirm: titleBtn,
        titleBtnCancel,
        type: type,
        alertRequest: type === 'request' ? true : false,
        tagImg,
        uuidAccountPayment: this.userProfile.uuidAccount,
        cardResponse: card,
        valueCard: this.param,
        accountData: this.accountData,
      },
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        switch (type) {
          case 'request': {
            this.checkRequisitionInProgressService(type);
            break;
          }
          case 'virtualRequest': {
            if(this.embossCard === true){
              this.requestVirtualCard()
              break;
            } else {
              this.checkRequisitionInProgressService(type)
            }
          }
          case 'unlock': {
            // this.token(data.data.document, type, card)
            // this.unlockService(card, data.data.document);
            break;
          }
        }
      }
    });
    return await modal.present();
  }

  public async token(type?: string, card?: any) {
    const modalToken = await this.modalController.create({
      component: ValidSmsPage,
      cssClass: 'modal-token',
      componentProps: {
        validationOnly: true,
        validationCard: true,
        type: type
      },
    });
    modalToken.onDidDismiss().then((data) => {
      if (data['data']) {
        const token = {
          code: data.data[0].token,
        };

        switch (type) {
          case 'request': {
            this.requestCardService(token);
            break;
          }
          case 'virtualRequest': {
            this.requestCardService(token);
            break;
          }
          case 'unlock': {
            this.unlockService(card);
            break;
          }
          default: {
            this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
          }
        }
      }
    });
    return await modalToken.present();
  }

  private async requestCardService(token) {
    this.loadingService.show();
    await this.cardService
      .requestCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, token)
      .then((res) => {
        const card = res;
        this.loadingService.hide();
        if (res.success === true) {
          if (this.embossCard === true) {
          this.modalCardDocument(
            'Cartão solicitado com sucesso!',
            'CARDS.CARD_MANAGER.CARD_REQUEST.BUTTON_SUCCESS',
            null,
            'success',
            'solicitation-sucess-card',
            card
          );
        } else {
          this.loadingService.hide();
          this.modalCardNotification('success', 'CARTÃO VIRTUAL SOLICITADO COM SUCESSO.');
          this.getCards(this.userProfile);
        }
          this.getCards(this.userProfile);
          this.cdr.detectChanges();
        } else {
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARD_REQUEST.MESSAGES.FAILED');
        console.error(err);
      });
  }

  public async unlockCard(card) {
    if(this.cardSelected.isVirtual === true || this.cardSelected.isVirtual === 1){
      this.validSms('virtualRequest');
    } else {
    const modal = await this.modalController.create({
      component: ModalUnlockCardComponent,
      cssClass: 'modal-custom-class',
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        const lengthToCompare = String(card.currentAccount.cardCheck).length;
        const dataEntered = String(data.data).substring(String(data.data).length - lengthToCompare);
        const dataToCompare = String(card.currentAccount.cardCheck);

        if (!!card.currentAccount && dataEntered === dataToCompare) {
          this.token('unlock', card);
        } else {
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.INVALID_NUMBERS');
        }
      }
    });
    return await modal.present();
  }
  }

  private async modalCardNotification(status: string, message: string) {
    const modal = await this.modalController.create({
      component: ModalCardNotificationComponent,
      cssClass: 'modal-custom-class',
      componentProps: {
        status,
        message,
      },
    });
    return await modal.present();
  }

  public async changePassword(card) {
    const modal = await this.modalController.create({
      component: UiCCardRedefinePasswordComponent,
      cssClass: 'ui-c-card-redefine-password',
      componentProps: {
        errorMessages: this.cardService.getFormMessageErrorPassword(),
        formCardPassword: this.cardService.createformCardPassword(),
        imgPath: 'assets/images/cash-out/payment-notification/confirmado.svg',
      },
      backdropDismiss: false,
    });
    modal.onDidDismiss().then((data) => {
      if (data.data !== undefined) {
        this.changePasswordService(data.data, card);
      }
    });

    return await modal.present();
  }

  public getDatePeriodFilter(currentDate: Date, periodDays: number): Date {
    return new Date(currentDate.setDate(currentDate.getDate() - periodDays));
  }

  private getExtractByPeriod(userProfile: UserInfoProfile, startDate: Date, endDate: Date): void {
    // if (startDate !== undefined && endDate !== undefined) {
    if (this.isVisibleExtractCart) {
      this.extractList = [];

      this.extractDigitalAccountService
        .getStatementCard(userProfile.uuidWhiteLabel, userProfile.uuidAccount, startDate, endDate)
        .pipe(
          tap((res) => {
            this.extractList = res;
            // console.log('extract-list', this.extractList);
          }),
          catchError((_) => {
            return of(null);
          })
        )
        .subscribe();
    }
  }

  public dateControl(date: any): string {
    try {
      return `${moment(this.formattedDate(date)).format('DD/MM/YYYY HH:mm')}`;
    } catch (err) {
      return '';
    }
  }

  private formattedDate(date) {
    if (date && typeof date === 'object' && date.constructor === Array) {
      if (date.length === 6) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4], date[5]);
      } else if (date.length === 5) {
        return new Date(date[0], date[1] - 1, date[2], date[3], date[4]);
      }
      return new Date(date[0], date[1], date[2]);
    }
    return date;
  }

  public showIsBalance(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsBalance(typeStatement);
  }

  public showIsCreditOrDebit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.showIsCreditOrDebit(typeStatement);
  }

  public isCredit(typeStatement: string): boolean {
    return this.extractDigitalAccountService.isCredit(typeStatement);
  }

  public valueDisplayCreditOrDebit(extractDigitalAccount: any): number {
    return this.extractDigitalAccountService.valueDisplayCreditOrDebit(extractDigitalAccount);
  }

  public doInfinite(event) {
    setTimeout(() => {
      this.pageLimit += 10;
      event.target.complete();
    }, 1000);
  }

  private async unlockService(card) {
    this.loadingService.show();
    await this.cardService
      .unlockCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, card)
      .then((res) => {
        this.loadingService.hide();
        if (res.statusCode === 400) {
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
        } else {
          this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.SUCCESS');
          this.getCards(this.userProfile);
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_ACTIVE.MESSAGES.FAILED');
      });
  }

  public async blockCard(card) {
    this.loadingService.show();
    await this.cardService
      .blockCard(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, card)
      .then((res) => {
        this.loadingService.hide();

        if (res.statusCode === 400) {
          this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_LOCK.MESSAGES.FAILED');
        } else {
          this.getCards(this.userProfile);
          this.modalCardNotification('success', 'CARDS.CARD_MANAGER.CARDS_LOCK.MESSAGES.SUCCESS');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'CARDS.CARD_MANAGER.CARDS_LOCK.MESSAGES.FAILED');
      });
  }

  private async changePasswordService(pass, card) {
    this.loadingService.show();
    const password = {
      newPassword: pass.newPassword,
      currentPassword: pass.currentPassword,
      card: {
        cardNumber: card.cardNumber,
        status: card.status,
        activateCardViaAdministrative: card.activateCardViaAdministrative,
        currentAccount: {
          currentAccountNumber: card.currentAccount.currentAccountNumber,
          cardCheck: card.currentAccount.cardCheck,
          proxyNumber: null,
        },
      },
    };
    await this.cardService
      .changePassword(this.userProfile.uuidWhiteLabel, this.userProfile.uuidAccount, password)
      .then((res) => {
        this.loadingService.hide();
        if (res.statusCode === 400) {
          this.modalCardNotification('solicitation-card', 'Erro ao trocar a senha do cartão');
        } else {
          this.modalCardNotification('success', 'Concluído!');
        }
      })
      .catch((err) => {
        this.loadingService.hide();
        this.modalCardNotification('solicitation-card', 'Erro ao trocar a senha do cartão');
      });
  }
}
