import { LayoutConfigService } from 'src/app/core/layout/services/layout-config.service';
import { Component } from '@angular/core';
import { AlertController, NavController } from '@ionic/angular';
import { QuizService} from '@wlp/ui-bs-signup'
import { Router } from '@angular/router';
import { tap, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { LoadingService } from 'src/app/core/layout/services/loading.service';

@Component({
  selector: 'ui-t-birth-date',
  templateUrl: './birth-date.page.html',
  styleUrls: ['./birth-date.page.scss'],
})
export class BirthDatePage {
  brand: string;
  navigationBackground: string;
  birthDate: string;
  zipCode: string;
  okZipCode = false
  okbirthDate = false
  public idDataFlow: string
  public typeFlow: string
  public quizData: any
  private cpfValue: string;
  private emailValue: string;
  private phoneValue: string;

  constructor(
    private navCtrl: NavController,
    private layoutConfigService: LayoutConfigService,
    private quizService: QuizService,
    private router: Router,
    private alertController: AlertController,
    private loading: LoadingService,
  ) {
    this.brand = this.layoutConfigService.getConfig('wlTheme.header.brand.logo.secondary');
    this.navigationBackground = this.layoutConfigService.getConfig('wlTheme.timeline.background.image');
  }


  goBack() {
    this.navCtrl.navigateForward('account-type');
  }

  toggleNav() {
    document.getElementById('toggle-nav').classList.toggle('open');
  }

  async ionViewWillEnter() {

  this.cpfValue = sessionStorage.getItem('cpfValue')

  this.emailValue = sessionStorage.getItem('emailValue')

  this.phoneValue = sessionStorage.getItem('phoneValue')

  this.idDataFlow = sessionStorage.getItem('idDataFlow')

  this.typeFlow = sessionStorage.getItem('typeFlow')

    this.quizService.getCampaing(this.idDataFlow)
    .pipe(
      tap((res) => {
        // //console.log('Resposta get', res)
      }),
      catchError((err) => {
        // //console.log('Erro', err)
        return of(null);
      })
    )
    .subscribe();
  }


  async continue() {

    sessionStorage.setItem('zipCodeValue', this.zipCode)
    sessionStorage.setItem('birthDateValue', this.birthDate)

    let onboardingId

    onboardingId = sessionStorage.getItem('keyForm') === null ? sessionStorage.getItem('preAccountValue') : sessionStorage.getItem('keyForm') 

    this.loading.show();
    let birthDateBody = this.birthDate.substring(6,10)+'-'+this.birthDate.substring(3,5)+'-'+this.birthDate.substring(0,2)

        //Body para o post
    let body = {
      "onboardingId": onboardingId,
      "parameters": {
        "campaignId": this.idDataFlow,
        "password": null,
        "parameters": [
          {
            "name": "CPF / CNPJ",
            "value": this.cpfValue
          },
          {
            "name": "Nascimento",
            "value": birthDateBody
          },
          {
            "name": "Endereço",
            "value": this.emailValue
          },
          {
            "name": "Telefone Completo",
            "value": this.phoneValue
          },
          {
            "name": "CEP",
            "value": this.zipCode
          }
        ]
      }
    }

    let postDataPersonalData = {
      "_id": onboardingId,
      "personalData": {
        "motherName": '',
        "birthDay": this.birthDate.substring(0,2)+'-'+this.birthDate.substring(3,5)+'-'+this.birthDate.substring(6,10),
        "monthlyIncome": "1,00",
        "marital": ""
      }
    }

    let addressData = {
      "_id": onboardingId,
      "addressData": {
        "cep": this.zipCode
      },
    }

    this.quizService.attPersonalData(onboardingId, postDataPersonalData)
      .pipe(
        tap((res) => {
          
        }),
        catchError((err) => {
          //console.log('Erro', err)
          return of(null);
        })
      )
      .subscribe();

    this.quizService.attAddressData(onboardingId, addressData)
    .pipe(
      tap((res) => {

      }),
      catchError((err) => {
        return of(null);
      })
    )
    .subscribe();


    this.quizService.verifyAccountQuiz(body)
    .pipe(
    tap((res) => {
      this.loading.hide();
      if(res.status === 'Negative'){
        this.showAlertError(res.executionMessage)
      } else if(res.status === 'Paused') {
        this.quizData = res
     this.router.navigate(['quiz-answer'], {
      state: {
        quizData: this.quizData,
        typeFlow: this.typeFlow
      },
    });
      } else {
        if (this.typeFlow === 'PHHYSICAL_PERSON' || this.typeFlow === 'PHYSICAL_PERSON') {
          this.navCtrl.navigateRoot('document-type')
        }
        if (this.typeFlow === 'LEGAL_PERSON') {
          this.navCtrl.navigateRoot('documents-partner')
        }
      }

    }),
    catchError((err) => {
      this.loading.hide();
      this.showAlertError('Tivemos um problema, por favor tente novamente.')
      return of(null);
    })
    )
    .subscribe();
  }

  checkDate() {
    if(this.birthDate.length < 10) {
      this.okbirthDate = false
    } else {
      this.okbirthDate = true
    }
  }

  checkCep(){
    if(this.zipCode.length < 9) {
      this.okZipCode = false
    } else {
      this.okZipCode = true
    }
  }

  async showAlertError(message) {
    const alert = await this.alertController.create({
      header: 'Atenção!',
      message: message,
      cssClass: 'custom-alert',
      buttons: ['OK'],
    });

    await alert.present();
  }

}
