import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AlertController, ModalController, NavParams } from '@ionic/angular';
import { UserInfoService } from '@wlp/ui-bs-login';
import { UserInfoProfile } from '@wlp/ui-bs-login/lib/dto/user-info-profile';
import { LoadingService } from 'src/app/core/layout/services/loading.service';
import { ValidSmsPage } from '../../pages/cp-manager/cash-out/valid-sms/valid-sms.page';
import { ImportFilesService } from '../../pages/cp-manager/import-files/import-files.service';

@Component({
  selector: 'ui-t-form-import-modal-files',
  templateUrl: './import-files-form-modal.page.html',
  styleUrls: ['./import-files-form-modal.page.scss'],
})
export class FormImportFilesModalPage {
  userProfile: UserInfoProfile;
  public user: any = {};
  private file: any = null;
  private codeAuthorization = '';
  protected nav;
  public showUploadButton = true;
  public summary = {
    totalTransactions: 0,
    totalAmountTransaction: 0,
  };
  public error: any = undefined;
  public fileType: string;

  constructor(
    private importFilesService: ImportFilesService,
    protected userInfoService: UserInfoService,
    private loadingService: LoadingService,
    private router: Router,
    public modalController: ModalController,
    private navParams: NavParams,
    public alertController: AlertController
  ) {
    this.nav = this.router.getCurrentNavigation();
    this.fileType = this.navParams.data.fileType;
  }

  async dismiss(payload: { success: boolean } | string) {
    await this.modalController.dismiss(payload);
  }

  ionViewWillEnter() {
    this.loadUser();
  }

  loadUser() {
    this.userInfoService
      .getInfoProfile()
      .then((user) => {
        this.userProfile = user;
      })
      .catch((error) => {
        console.error(error);
      });
  }

  async handleSubmit() {
    const modalToken = await this.modalController.create({
      component: ValidSmsPage,
      cssClass: 'modal-resume',
    });

    modalToken.onDidDismiss().then((data) => {
      if (data.data[0].validToken === true) {
        this.codeAuthorization = data.data[0].token;
        this.uploadFile();
      }
    });

    return await modalToken.present();
  }

  async handleUploadFile() {
    const response = await this.importFilesService.translateFile(
      this.file.file,
      this.fileType,
      this.userProfile.uuidAccount
    );
    if (!response) {
      const alert = await this.alertController.create({
        cssClass: 'invalid-file',
        header: 'Atenção',
        subHeader: 'O arquivo enviado não pôde ser lido!',
        message: 'Verifique se o layout está correto.',
        buttons: ['OK'],
      });
      await alert.present();
    }

    // popular o campo resumo
    this.summary = {
      totalTransactions: response.fileData.transactions.length,
      totalAmountTransaction: response.fileData.transactions.reduce((acc, cur) => acc + cur.operationValue, 0),
    };
    this.showUploadButton = false;
  }

  async uploadFile() {
    await this.loadingService.show();
    let success = false;
    try {
      await this.importFilesService.uploadFile(
        this.file.file,
        this.codeAuthorization,
        this.fileType,
        this.userProfile.uuidAccount
      );
      success = true;
    } finally {
      await this.loadingService.hide();
    }
    await this.dismiss({ success });
    this.goToImportFileList();
  }

  goToImportFileList() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
    this.router.onSameUrlNavigation = 'reload';
    this.router.navigateByUrl('/app/import-files');
  }

  getUploadFile(event) {
    this.file = event;
  }
}
